.App {
  text-align: center;
  margin-top: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#nick-input, #lang, #haupt_header {
  color: #2396c7;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

select {
  background-color: #363535;
  /*color: #97bbbd;*/
  color: #2396c7;
}

.weiter{
  height: 3.3em!important;
}
.weiter:hover{
  background-color: #779aad !important;
}

.lanf{
  font-size: small;
  color: #fd7070;
}

.themen_list {
  color: darkgrey!important;
}

.App-header {
  background-color: rgb(56, 59, 57);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(5px + 2vmin);
  color: #fd7070;
}

.d-grid {
  display: grid!important;
  align-content: flex-start;
}

.victory_block{
  color: #2396c7;
  font-size: large;
}

.App-link {
  color: #61dafb;
}

.Word {
  width: 100%;
  text-align: center;
  border: 1px solid #5d5c54;
  border-radius: 20px;
  margin-bottom: 2px;
  margin-top: 20px;
}

input {
  color: antiquewhite;
  border: 1px solid #97bbbd;
  padding-left: 0.4rem;
  background-color: #363535;
}

.example {
  font-size: 22px;
  line-height: 25px;
  font-style: italic;
  color: #2396c7;
}

.word_res {
  font-size: 30px;
  line-height: 32px;
}

.definition{
  color: #2396c7;
  font-size: 25px;
  font-family: "Arial", sans-serif;
  margin: 5px;
  line-height: 27px;
}

.definition img{
  width: 85%;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #2396c7;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  /*max-height: 143px;*/
  overflow-y: auto;
  padding-left: 0;
  /*width: calc(300px + 1rem);*/
}

.suggestions li {
  /*padding: 0.5rem;*/
}

.suggestion-active,
.suggestions li:hover {
  background-color: #737973;
  color: #2a2a28;
  cursor: pointer;
  font-weight: 500;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.hidden { display:none; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
