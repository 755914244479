@import url(
'https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;800&display=swap');

body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
   /* flex-direction: column;*/
    font-family: 'Poppins', sans-serif;

}

img {
    width: 50%;
}

.invert {
    mix-blend-mode: plus-lighter;
}

.shimmer {
    color: grey;
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
    100% {-webkit-mask-position:left}
}

.example {
    font-style: italic;
    font-size: 16px;
    background-color: #0c0c0c;
}

.invert > pre{
    line-height:1.2em;
    background:linear-gradient(180deg, #2d2b2b 0, black 1.2em, black 0);
    background-size:2.4em 2.4em;
    background-origin:content-box;

    background-color: black!important;
    color: #75e322;

    /* some extra styles*/
    padding:0 20px;
    text-align:justify;
    font-family:calibri,arial,sans-serif;
}

.container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.sticky{
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 999;
    align-self: start; /* <-- this is the fix */
}

.crossword {
    display: grid;
    grid-template-rows: repeat(13, 60px);
    box-shadow: 0 0 3px 3px gray;
}

.row {
    display: grid!important;
    grid-template-columns: repeat(13, 60px);
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
}

.cell {
    width: 60px;
    height: 60px;
    background-color: white;
    border: 1px solid #8e9698;
}

.unused {
    background-color: #2d312d;
}

h1 {
    text-align: center;
    color: #c1cbc1;
    font-size: 2.2rem;
}

.number {
    position: relative;
    font-weight: 300;
    color: #000;
    bottom: 60px;
    left: 3px;
    z-index: 10;
}

#crossword_cell:focus {
    outline: none;
    color: black;
    width: 60px;
    height: 60px;
}

#crossword_cell {
    display: block;
    position: relative;
    color: black;
    font-size: 3em;
    text-align: center;
    height: 60px;
    width: 60px;
    background-color: transparent;
    /*border: 0 solid;*/
    z-index: 1;
}

#def_block{
    padding-left: 15px;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    /*padding-left: 0!important;*/
    margin-top: var(--bs-gutter-y);
}

.questions {
    max-width: 40%;
}

h2 {
    font-size: 2rem;
    color: #5d5c54;
    text-align: center;
}

#questionsList {
    line-height: 2.7rem;
    font-size: 1.2rem;
}

#questionsList li {
    text-align: center;
    list-style-position: inside;
}

.hidden {
    display: none;
}

.show {
    display: block;
    border-radius: 5px;
}

#answers {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-around;
    visibility: hidden;
    color: green;
    margin: 1.5rem;
}

.def_element{
    color: #8e9698;
}

.definitions{
    padding-left: 15px;
    padding-right: 15px;
}


@media screen and (max-width:1260px) {
    .container {
        flex-direction: row;
        align-items: start;
    }

    #def_block{
        padding-left: 0;
    }

    .crossword {
        display: grid;
        grid-template-rows: repeat(13, 40px);
    }

    .row {
        display: grid;
        grid-template-columns: repeat(13, 40px);
    }

    .sticky{
        align-self: start; /* <-- this is the fix */
    }

    .cell {
        width: 40px;
        height: 40px;
    }

    #crossword_cell{
        width: 40px;
        height: 40px;
        font-size: 1.5rem;
    }

    #crossword_cell:focus {

        height: 40px;
        width: 40px;
    }

    .number {
        bottom: 47px;
        font-size: .8rem;
    }

    .questions {
        max-width: 100%;
    }

}

@media screen and (max-width:860px) {

    .container{
        flex-direction: column;
        align-items: center;
    }

    #crossword_cell{
        width: 27px;
        height: 27px;
        font-size: 1rem;
    }

    .sticky{
        align-self: center; /* <-- this is the fix */
    }


    .crossword {
        display: grid;
        grid-template-rows: repeat(13, 27px);
    }

    .row {
        display: grid;
        grid-template-columns: repeat(13, 27px);
    }

    .cell {
        width: 27px;
        height: 27px;
    }

    ol {
        line-height: 2rem;
        font-size: 1rem;
    }

    #crossword_cell {
        width: 27px;
        height: 27px;
        font-size: 1rem;
        font-family: 'Poppins', sans-serif;
    }

    #crossword_cell:focus {
        height: 27px;
        width: 27px;
    }

    .number {
        bottom: 37px;
        font-size: .7rem;
    }

    .questions {
        max-width: 100%;
    }
}

@media screen and (max-width:280px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .crossword {
        display: grid;
        grid-template-rows: repeat(13, 20px);
    }

    .sticky{
        align-self: start; /* <-- this is the fix */
    }

    #crossword_cell{
        width: 20px;
        height: 20px;
        font-size: 1rem;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(13, 20px);
    }

    .cell {
        width: 20px;
        height: 20px;
    }

    ol {
        line-height: 2rem;
        font-size: 1rem;
    }

    #crossword_cell {
        width: 20px;
        height: 20px;
        font-size: .5rem;
    }

    #crossword_cell:focus {
        height: 20px;
        width: 20px;
    }

    .number {
        bottom: 37px;
        font-size: .7rem;
    }

    .questions {
        max-width: 100%;
    }
}

@media (orientation: landscape)  {

    .container{
        flex-direction: row;
        justify-content: start;
    }

    .sticky{
        align-self: start; /* <-- this is the fix */
    }

}

